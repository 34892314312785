/*
 * @Description: 
 * @Author: Chen Bi Qi
 * @Date: 2020-09-15 22:57:49
 * @LastEditTime: 2021-02-06 15:36:50
 */
import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import util from "@/utils/util";
import {
  message
} from 'ant-design-vue';
import {
  getAllAuth,
  getMe,
  getInstInfo
} from "@/request/api";

Vue.use(Vuex)

const ADD_COUNT = 'ADD_COUNT'; // 用常量代替事件类型，使得代码更清晰
const REMOVE_COUNT = 'REMOVE_COUNT';
const ADD_VERSION = 'ADD_VERSION'
export default new Vuex.Store({
  state: {
    token: '',
    version: '',
    isAdmin: null,
    inst: {},
    useRouter: localStorage.getItem("useRouter") == null ? [] : JSON.parse(localStorage.getItem("useRouter")),
    useAuth: [],
    collapsed:true,
    selectedKeys:[]
  },
  mutations: {
    //写法与getters相类似
    //组件想要对于vuex 中的数据进行的处理
    //组件中采用this.$store.commit('方法名') 的方式调用，实现充分解耦
    //内部操作必须在此刻完成(同步)
    [ADD_COUNT] (state, token) { // 第一个参数为 state 用于变更状态 登录
      localStorage.setItem("token", token);
      state.token = token;
    },
    [ADD_VERSION] (state, version) {
      localStorage.setItem("version", version);
      state.version = version;
    },
    // 更新选中的权限菜单栏
    UPDATE_SELECTEDKEYS(state, path){
      // console.log(Vue)
      // console.log(router.app._route.path)
      // console.log(router)
      state.selectedKeys = path
    },

    REMOVE_COUNT (state) { // 退出登录

     

      localStorage.removeItem("token");
      localStorage.removeItem("isAdmin");
      // localStorage.removeItem("allAuth");
      localStorage.removeItem("useRouter");
      
      localStorage.removeItem("user");
      localStorage.removeItem("useAuth");
      localStorage.removeItem("expandAuth");
      localStorage.removeItem("version");
      localStorage.removeItem("agent_inst");
      localStorage.removeItem("Login-Type");
      
      state.token = ""
      router.push({
        path: "/login"
      });
      message.success('退出成功！');
      // 清除inst和MEID的操作要在router.push({path: "/login"});的操作后面，因为inst和MEID中的信息要在退出登陆前发送给记录页面停留时间的接口
      localStorage.removeItem("inst");
      localStorage.removeItem('MEID')
    },

    /**
     * @description: 获取账户，是否管理员
     * @param {type} 
     * @return {type} 
     */
    SET_ADMIN (state, data) {
      // console.log(data)
      let arr = [];
      data.columns.forEach((item) => {
        arr.push(...item.child)
      })
      state.isAdmin = data.is_admin == 1 ? true : false
      state.useAuth = arr
      // console.log(arr)
      localStorage.setItem("isAdmin", data.is_admin == 1 ? true : false);
      localStorage.setItem("useAuth", JSON.stringify(arr));
      localStorage.setItem("expandAuth", JSON.stringify(data.expand_module));
      localStorage.setItem("agent_inst", data.agent_inst);
      localStorage.setItem('MEID', data.id)
      localStorage.setItem('head_img', data.head_img)
    },
    /**
     * @description: 获取食堂信息
     * @param {type} 
     * @return {type} 
     */
    SET_INST (state, data) {
      state.inst = data
      localStorage.setItem("inst", JSON.stringify(data));

    },

    /**
     * @description: 在此函数生成权限路由
     * @param {type} 
     * @return {type} 
     */
     SET_AUTH (state, data) {
        let oldRouter = router.options.routes[1].children,
            newRouter = util.updateUseRouter(oldRouter);
            // console.log(newRouter)
        state.useRouter = newRouter
        // console.log(state.useRouter)
        localStorage.setItem('useRouter', JSON.stringify(newRouter));

        router.options.routes[1].children = newRouter
     },

    /**
     * @description: 在此函数生成权限路由 于2021/08/27注释，替换成上面的方法
     * @param {type} 
     * @return {type} 
     */
    // SET_AUTH (state, data) {
    //   let inst = JSON.parse(localStorage.getItem('inst'))
    //   let isAdmin = localStorage.getItem("isAdmin");
    //   let useAuth = JSON.parse(localStorage.getItem("useAuth"));
    //   let expandAuth = JSON.parse(localStorage.getItem('expandAuth'));
    //   let agent_inst = JSON.parse(localStorage.getItem("agent_inst"));
    //   console.log(useAuth)
    //   try {
    //     let newRouter = router.options.routes[1].children;
    //     newRouter.forEach((element) => {
    //       // 判断拓展应用及下级权限的显隐
    //       if (element.name == "拓展应用") {
    //         if (isAdmin == "true") {
    //           element.children.forEach((item) => {
    //             if (item.name == "问卷调查") {
    //               if (inst.version.version != 0) {
    //                 element.meta.show = true;
    //                 item.meta.requireAuth = true;
    //               } else {
    //                 element.meta.show = false;
    //                 item.meta.requireAuth = false;
    //               }
    //             }
    //           })
    //         } else {
    //           element.meta.show = false;
    //         }
    //       }
    //       if (element.name == "单位管理") {
    //         // 判断是否有查看单位管理
    //         if (isAdmin == "true") {
    //           // 判断用户是否有对应权限
    //           element.children.forEach((item) => {
    //             if (item.name == "代报餐管理") {
    //               if (inst.flag.indexOf(1) != -1) {
    //                 item.meta.requireAuth = true;
    //               } else {
    //                 item.meta.requireAuth = false;
    //               }
    //             } else if (item.name == "子管理员") {
    //               if (inst.version.version != 0) {
    //                 item.meta.requireAuth = true;
    //               } else {
    //                 item.meta.requireAuth = false;
    //               }
    //             } else if (item.name == "审核账号管理") {
    //               if (inst.version.version != 0) {
    //                 item.meta.requireAuth = true;
    //               } else {
    //                 item.meta.requireAuth = false;
    //               }
    //             } else if (item.name == "模式切换") {
    //               if (inst.version.version != 0) {
    //                 item.meta.requireAuth = true;
    //               } else {
    //                 item.meta.requireAuth = false;
    //               }
    //             }
    //           });
    //           element.meta.show = true;
    //         } else {
    //           element.meta.show = false;
    //         }
    //       } else if (
    //         element.name != "单位管理" &&
    //         element.name != "首页" &&
    //         element.name != "经营报表"
    //       ) {
    //         element.children.forEach((item) => {
              
    //           let find = util.findIndex(item.name, useAuth);
    //           if (find == -1) {
    //             if (isAdmin == "true") {
    //               item.meta.requireAuth = true;
    //               item.meta.enterAuth = false;
    //             } else {
    //               item.meta.requireAuth = false;
    //               item.meta.enterAuth = false;
    //             }
    //           } else {
    //             item.meta.requireAuth = true;
    //             item.meta.enterAuth = true;
    //           }
    //         });
    //       }
    //       if(element.name == '拓展应用'){
    //         if(agent_inst){
    //           element.meta.show = false;
    //         }
    //       }
    //       if(element.name == '用餐管理'){
    //         element.children.forEach((item) => {
    //           if (item.name == "消费二维码") {
    //             if(agent_inst){
    //               item.meta.requireAuth = false;
    //             }
    //           }
    //         });
    //       }
    //       if(element.name == '单位管理'){
    //         element.children.forEach((item) => {
    //           if (item.name == "版本信息") {
    //             if(agent_inst){
    //               item.meta.requireAuth = false;
    //             }else{
    //               item.meta.requireAuth = true;
    //             }
    //           }
    //         });
    //       }
    //     });

    //     state.useRouter = newRouter
    //     localStorage.setItem('useRouter', JSON.stringify(newRouter));

    //     router.options.routes[1].children = newRouter
    //   } catch (e) {
    //     console.log(e)
    //   }



    // },

    // 左侧导航栏伸展状态更改
    CHANGE_COLLAPSED(state){
      state.collapsed = !state.collapsed
    },

    //左侧导航栏伸展状态更改为收
    CHANGE_COLLAPSED_TRUE(state){
      state.collapsed = true
    },

    //修改模块重新生成路由
    SET_NEW_ROUTER(state, data){

    }

  },
  actions: {

    GET_ALL_AUTH ({ }, data) {
      return new Promise((resolve, reject) => {
        getAllAuth(data).then(res => {
          resolve(res)
          this.commit('SET_AUTH', res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },

    GET_ME ({ }, data) {
      // console.log(data)
      return new Promise((resolve, reject) => {
        getMe(data).then(res => {
          resolve(res)
          this.commit('SET_ADMIN', res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },

    GET_INST_INFO ({ }, data) {
      return new Promise((resolve, reject) => {
        getInstInfo(data).then(res => {

          resolve(res)
          this.commit('SET_INST', res.data)
        }).catch(err => {
          reject(err)
        })
      })
    }

  },
  modules: {}
})