<template>
  <div class="main">
    登录中，请稍候
  </div>
</template>

<script>
import {
  easyLogin
} from "@/request/api";
export default {
  data() {
    return {
      islogin:false,
      adminid:0
    };
  },
  computed: {},
  components: {},
  created() {
    console.log("created_at");
    this.checkEasyLogin()
  },
  mounted() {},
  methods: {
    //免密登录
    checkEasyLogin(){
      let loginType = this.$route.query.logintype;
      
      if(loginType == 'easy'){
        
        this.adminid = this.$route.query.adminid;
        
        //用户使用免密登录
        let parame = {
          inst:this.$route.query.inst,
          nonce:this.$route.query.nonce,
          timestamp:this.$route.query.timestamp
        };

        easyLogin(parame)
          .then(async (res) => {
            // window.history.replaceState(null,null,'');
            if(res.data.error_code == 1){
              this.$message.error(res.data.message);
              return;
            }
            localStorage.setItem('Login-Type','easyLogin');
            localStorage.setItem('adminid',this.adminid+'/'+parame.inst);
            this.$message.success("登录成功!");
            this.$store.commit("ADD_COUNT", res.data.access_token);
            this.$store.commit("ADD_VERSION", res.data.version);
            await this.$store.dispatch("GET_INST_INFO");
            this.$store.dispatch("GET_ME").then((res) => {
              this.$store.dispatch("GET_ALL_AUTH").then(async (res) => {
                this.$router.push("/");
              });
            });
          })
          .catch((err) => {
            console.log(err)
          });
      }
    }
  },
};
</script>
<style lang='less' scoped>
@import '~@/style/style.less';
.main {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  .grayBc {
    position: relative;
    padding: 15px 20px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 10%,
      rgba(0, 0, 0, 0.3) 100%
    );
    width: 800px;
    height: 240px;
    .blue {
      color: @color-main;
      margin-bottom: 0;
      font-size: 20px;
    }
    .light_blue {
      color: #cee1e9;
      margin-top: 0;
      font-size: 11px;
    }
    .forgetPwdBtn {
      border: 1px solid #cee1e9;
      color: #cee1e9;
      width: 160px;
      height: 35px;
      text-align: center;
      line-height: 30px;
    }
    .forgetPwdBtn:hover {
      cursor: pointer;
      color: white;
      border: 1px solid white;
    }
    .form_Box {
      position: absolute;
      right: 30px;
      top: -50px;
      width: 380px;
      height: 320px;
      background-color: #fafafa;
      padding: 20px 20px 10px 20px;
      .sign_title {
        color: @color-main;
        font-size: 23px;
        font-weight: 100;
      }
      .ant-form-item {
        margin: 0;
      }
      .sendCaptchaBtn {
        
        color:#fff;
        background-color:#FF833D;
      }
      .verification_pic {
        // z-index: 999!important;
        width: 100px;
        height: 30px;
        position: absolute;
        top: -6px;
        right: 1px;
      }
      .changePic {
        float: right;
        color: #12a6fe;
        margin: 0;
      }
      .login-type-card{
        margin:0 0 20px;
      }
      .login-type-card a{
        color:#000;
        font-weight:500;
        font-size:18px;
        margin-right:20px;
      }
      .login-type-sel{
        color:#FF833D!important;
        border-bottom:2px solid #FF833D;
      }
      .err-tips{
        color:red;
        padding-left:15px;
        font-size:8px;
        position: absolute;
        top: 104px;
        z-index:999;
      }
      .err-tips2{
        color:red;
        padding-left:15px;
        font-size:8px;
        position: absolute;
        top: 160px;
        z-index:999;
      }
      .err-tips3{
        color:red;
        padding-left:15px;
        font-size:8px;
        position: absolute;
        top: 215px;
        z-index:999;
      }
      .err-tips4{
        color:red;
        padding-left:15px;
        font-size:8px;
        position: absolute;
        top: 100px;
        z-index:999;
      }
      .err-tips5{
        color:red;
        padding-left:15px;
        font-size:8px;
        position: absolute;
        top: 156px;
        z-index:999;
      }
    }
	.radio-item-info{
		display: block;
		padding-left: 25px;
	}
	.ant-btn-info{
		color: #FF833D;
		background-color: #fff;
		border-color: #FF833D;
		text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
		box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
		margin-left: 10px;
	}
  }
}
</style>
