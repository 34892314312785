import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import easyLogin from '../views/easyLogin.vue'
import store from '@/store'
import {
  Modal,
  message
} from 'ant-design-vue';
Vue.use(VueRouter)

import util from "@/utils/util";
import { sensorsAnalytics,moduleStayTime } from "@/request/api";



export const routes = [{
  path: '/login',
  name: 'Login',
  component: Login,
  meta: {
    requireAuth: false
  }
},
{
  path: '/',
  name: 'Home',
  component: Home,
  redirect: '/Dashboard',
  meta: {
    requireAuth: true,
    parent: 'Home'
  },
  children: [{
    path: '/Dashboard',
    name: '首页',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      requireAuth: true,
      parent: 'Home',
      icon: 'home',
      parentName: 'Home',
      show: true
    }
  },
  // {
  //   path: '/Renew',
  //   name: '首页',
  //   component: () => import('@/views/renew.vue'),
  //   meta: {
  //     requireAuth: false,
  //     parent: 'Home',
  //     icon: 'home',
  //     parentName: 'Home',
  //     show: false
  //   }
  // },
  //人员管理
  {
    path: '/dinnersManage',
    name: '人员管理',
    component: () => import('@/views/CommonSeconRouerview.vue'),
    meta: {
      requireAuth: true,
      parent: '人员管理',
      icon: "team",
      show: true
    },
    children: [{
      path: '/DepartmentSet',
      name: '部门设置',
      component: () => import('@/views/dinnersManage/DepartmentSet.vue'),
      meta: {
        requireAuth: true,
        parent: '人员管理',
        enterAuth: true,

      }
    },
    {
      path: '/JobSet',
      name: '职务设置',
      component: () => import('@/views/dinnersManage/JobSet.vue'),
      meta: {
        requireAuth: true,
        parent: '人员管理',
        enterAuth: true,

      }
    },
    {
      path: '/PersonInfoManage',
      name: '人员管理',
      component: () => import('@/views/dinnersManage/PersonInfoManage.vue'),
      meta: {
        requireAuth: true,
        parent: '人员管理',
        enterAuth: true,

      }
    },
    {
      path: '/NotAuditList',
      name: '免审核名单',
      component: () => import('@/views/dinnersManage/NotAuditList.vue'),
      meta: {
        requireAuth: true,
        parent: '人员管理',
        enterAuth: true,

      }
    },
    {
      path: '/AddDinner',
      name: '添加人员',
      component: () => import('@/views/dinnersManage/AddDinner.vue'),
      meta: {
        requireAuth: true,
        parent: '人员管理',
        enterAuth: true,

      }
    },
    {
      path: '/AdministratorAssignment',
      name: '子管理员',
      component: () => import('@/views/institutionsManage/AdministratorAssignment.vue'),
      meta: {
        requireAuth: true,
        parent: '单位管理',
        enterAuth: true,
        moduleId: 60
      }
    }
    ]
  },

  //用餐管理设置模块
  {
    path: '/mealsManage',
    name: '用餐/消费管理',
    component: () => import('@/views/CommonSeconRouerview.vue'),
    meta: {
      requireAuth: true,
      parent: '用餐/消费管理',
      icon: "coffee",
      show: true
    },
    children: [{
      path: '/OpenDinnerDate',
      name: '开餐日期',
      component: () => import('@/views/mealsManage/OpenDinnerDate.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    }, {
      path: '/MealTiming',
      name: '用餐时段',
      component: () => import('@/views/mealsManage/MealTiming.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    },{
      path: '/BatchMealTiming',
      name: '用餐时段设置',
      component: () => import('@/views/mealsManage/BatchMealTiming.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    }, {
      path: '/CoursesStorage',
      name: '菜品库',
      component: () => import('@/views/mealsManage/CoursesStorage.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    }, {
      path: '/EveryDayMenu',
      name: '每日菜谱',
      component: () => import('@/views/mealsManage/EveryDayMenu.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    },
    {
      path: '/MealDetail',
      name: '报餐明细',
      component: () => import('@/views/mealsManage/MealDetail.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    }, {
      path: '/MealStatistics',
      name: '报餐统计',
      component: () => import('@/views/mealsManage/MealStatistics.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    }, {
      path: '/ShopGoodsManage',
      name: '小卖部商品管理',
      component: () => import('@/views/mealsManage/ShopGoodsManage.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    }, {
      path: '/ShopOrdersManage',
      name: '小卖部订单管理',
      component: () => import('@/views/mealsManage/ShopOrdersManage.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    }, {
      path: '/ExpenseQRcode',
      name: '消费二维码',
      component: () => import('@/views/mealsManage/ExpenseQRcode.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    },
    {
      path: '/EstimateList',
      name: '评论列表',
      component: () => import('@/views/mealsManage/EstimateList.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    }, {
      path: '/AntistopList',
      name: '关键词列表',
      component: () => import('@/views/mealsManage/AntistopList.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    },
    {
      path: '/SceneOrder',
      name: '现场点餐',
      component: () => import('@/views/mealsManage/SceneOrder.vue'),
      meta: {
        requireAuth: true,
        parent: '用餐/消费管理',
        enterAuth: true,
      }
    }
    ]
  },

  //账户管理
  {
    path: '/accountManage',
    name: '账户管理',
    component: () => import('@/views/CommonSeconRouerview.vue'),
    meta: {
      requireAuth: true,
      parent: '账户管理',
      icon: "pay-circle",
      show: true
    },
    children: [
      // {
      //   path: '/CollectionAccount',
      //   name: '收款账户',
      //   component: () => import('@/views/accountManage/CollectionAccount.vue'),
      //   meta: {
      //     requireAuth: true,
      //     parent: '账户管理'
      //   }
      // },
      {
        path: '/AccountRecharge',
        name: '账户充值',
        component: () => import('@/views/accountManage/AccountRecharge.vue'),
        meta: {
          requireAuth: true,
          parent: '账户管理',
          enterAuth: true,
        }
      },
      {
        path: '/AccountDetail',
        name: '账户明细',
        component: () => import('@/views/accountManage/AccountDetail.vue'),
        meta: {
          requireAuth: true,
          parent: '账户管理',
          enterAuth: true,
        }
      },
      // {
      //   path: '/CheckReport',
      //   name: '查看报表',
      //   component: () => import('@/views/accountManage/CheckReport.vue'),
      //   meta: {
      //     requireAuth: true,
      //     parent: '账户管理'
      //   }
      // },
    ]
  },

  //拓展功能(原系统设置+拓展应用+代报餐三项合并)
  {
    path: '/systemConfig',
    name: '拓展功能',
    component: () => import('@/views/CommonSeconRouerview.vue'),
    meta: {
      requireAuth: true,
      parent: '拓展功能',
      icon: "tool",
      show: true
    },
    children: [{
        path: '/NoticesManage',
        name: '公告管理',
        component: () => import('@/views/systemConfig/NoticesManage.vue'),
        meta: {
          requireAuth: true,
          parent: '拓展功能',
          enterAuth: true,
        }
      }, {
        path: '/CookBookPicUpload',
        name: '菜谱图片',
        component: () => import('@/views/systemConfig/CookBookPicUpload.vue'),
        meta: {
          requireAuth: true,
          parent: '拓展功能',
          enterAuth: true,
        }
      }, {
        path: '/HomeSwiperUpload',
        name: '首页幻灯片',
        component: () => import('@/views/systemConfig/HomeSwiperUpload.vue'),
        meta: {
          requireAuth: true,
          parent: '拓展功能',
          enterAuth: true,
        }
      }, {
        path: '/DeviceConnection',
        name: '设备连接',
        component: () => import('@/views/systemConfig/DeviceConnection.vue'),
        meta: {
          requireAuth: true,
          parent: '拓展功能',
          enterAuth: true,
        }
      },
      {
        path: '/Questionnaire',
        name: '问卷调查',
        component: () => import('@/views/expandApp/Questionnaire.vue'),
        meta: {
          requireAuth: true,
          parent: '拓展应用',
          enterAuth: true,
          moduleId: 66
        }
      },
      {
        path: '/TaRenDaiBaoCan',
        name: '他人代报餐',
        component: () => import('@/views/expandApp/TaRenDaiBaoCan.vue'),
        meta: {
          requireAuth: true,
          parent: '拓展应用',
          enterAuth: true,
          moduleId: 67
        }
      },{
        path: '/ReplaceApplyMealManage',
        name: '自动代报餐',
        component: () => import('@/views/institutionsManage/ReplaceApplyMealManage.vue'),
        meta: {
          requireAuth: true,
          parent: '单位管理',
          enterAuth: true,
          moduleId: 64
        }
      }]
  },



  //经营报表模块
  {
    path: '/operatingStatement',
    name: '经营报表',
    component: () => import('@/views/CommonSeconRouerview.vue'),
    meta: {
      requireAuth: true,
      parent: '经营报表',
      icon: 'bar-chart',
      show: true
    },
    children: [{
      path: '/UserAccountReport',
      name: '用户账户报告',
      component: () => import('@/views/operatingStatement/UserAccountReport.vue'),
      meta: {
        requireAuth: true,
        parent: '经营报表',
        enterAuth: true,
      }
    },
    {
      path: '/UserConsumeReport',
      name: '用户消费报告',
      component: () => import('@/views/operatingStatement/UserConsumeReport.vue'),
      meta: {
        requireAuth: true,
        parent: '经营报表',
        enterAuth: true,

      }
    },
    {
      path: '/CanteenOperationReport',
      name: '食堂经营报告',
      component: () => import('@/views/operatingStatement/CanteenOperationReport.vue'),
      meta: {
        requireAuth: true,
        parent: '经营报表',
        enterAuth: true,

      }
    },
    {
      path: '/TimeMealStatus',
      name: '时段就餐统计',
      component: () => import('@/views/operatingStatement/TimeMealStatus.vue'),
      meta: {
        requireAuth: true,
        parent: '经营报表',
        enterAuth: true,

      }
    },
    {
      path: '/NoMealPersons',
      name: '未实际就餐统计',
      component: () => import('@/views/operatingStatement/NoMealPersons.vue'),
      meta: {
        requireAuth: true,
        parent: '经营报表',
        enterAuth: true,

      }
    },
    {
      path: '/FirstOfferStatus',
      name: '首份优惠统计',
      component: () => import('@/views/operatingStatement/FirstOfferStatus.vue'),
      meta: {
        requireAuth: true,
        parent: '经营报表',
        enterAuth: true,

      }
    },
    {
      path: '/UserMealReport',
      name: '人员就餐统计',
      component: () => import('@/views/operatingStatement/UserMealReport.vue'),
      meta: {
        requireAuth: true,
        parent: '经营报表',
        enterAuth: true,

      }
    }
    ]
  },

  //  单位管理模块（仅超级管理员可见）
  {
    path: '/institutionsManage',
    name: '单位管理',
    component: () => import('@/views/CommonSeconRouerview.vue'),
    meta: {
      requireAuth: true,
      parent: '单位管理',
      icon: "hdd",
      show: false
    },
    children: [{
      path: '/InstitutionsInfos',
      name: '单位信息',
      component: () => import('@/views/institutionsManage/InstitutionsInfos.vue'),
      meta: {
        requireAuth: true,
        parent: '单位管理',
        enterAuth: true,
        moduleId: 59
      }
    },
    {
      path: '/VersionInformation',
      name: '配置及续费',
      component: () => import('@/views/renew.vue'),
      // component: () => import('@/views/institutionsManage/VersionInformationNew.vue'),
      // component: () => import('@/views/institutionsManage/VersionInformation.vue'),
      meta: {
        requireAuth: true,
        enterAuth: true,
        parent: '单位管理',
        moduleId: 61
      }
    },
    {
      path: '/ServiceModules',
      name: '服务购买',
      component: () => import('@/views/serviceManage/serviceModules.vue'),
      meta: {
        requireAuth: false,
        enterAuth: true,
        parent: '单位管理'
      }
    },
    {
      path: '/ServiceOrder',
      name: '服务订单',
      component: () => import('@/views/serviceManage/serviceOrder.vue'),
      meta: {
        requireAuth: false,
        enterAuth: true,
        parent: '单位管理'
      }
    },
    {
      path: '/OrderDetails',
      name: '订单与发票管理',
      component: () => import('@/views/institutionsManage/OrderDetails.vue'),
      meta: {
        requireAuth: true,
        enterAuth: true,
        parent: '单位管理',
        moduleId: 61
      }
    },
    {
      path: '/AuditAccountManage',
      name: '审核账号管理',
      component: () => import('@/views/institutionsManage/AuditAccountManage.vue'),
      meta: {
        requireAuth: true,
        parent: '单位管理',
        enterAuth: true,
        moduleId: 62
      }
    },
    {
      path: '/VerificationModeSwitch',
      name: '其他设置',
      component: () => import('@/views/institutionsManage/VerificationModeSwitch.vue'),
      meta: {
        requireAuth: true,
        parent: '单位管理',
        enterAuth: true,
        moduleId: 63
      }
    },
    {
      path: '/CouponManege',
      name: '优惠券',
      component: () => import('@/views/institutionsManage/CouponManege.vue'),
      meta: {
        requireAuth: false,
        enterAuth: true,
        parent: '单位管理'
      }
    }
    // {
    //   path: '/ModuleManage',
    //   name: '切换模式',
    //   component: () => import('@/views/institutionsManage/ModuleManage.vue'),
    //   meta: {
    //     requireAuth: true,
    //     parent: '单位管理',
    //     enterAuth: false,
    //     moduleId: 65
    //   }
    // }
    ]
  },
  {
    path: 'ModuleList',
    name: '更多功能及硬件',
    component: () => import('@/views/institutionsManage/ModuleList.vue'),
    meta: {
      requireAuth: true,
      parent: '更多功能及硬件',
      icon: "appstore",
      show: true
    }
  }
  ]
},
{
  path: '/easyLogin',
  name: 'easyLogin',
  component: easyLogin,
  meta: {
    requireAuth: false
  }
},
{
  path: '/tips',
  name: 'tips',
  component: () => import('@/views/errPage/tips.vue'),
  meta: {
    requireAuth: false
  }
},
{
  name: '404',
  path: '/404',
  component: () => import('@/views/errPage/index.vue')
}, {
  path: '*',
  redirect: '/404'
}
]

//start:往除了首页(没有子模块)，经营报表，单位管理之外的每个模块路由.meta中添加由接口返回来的对应模块权限的moduleId
//ps:经营报表，单位管理模块下的模块id已经与后端约定好且模块的moduleId在此页面路由中写死
let addModuleId = (useAuth) => {
  routes[1].children.forEach(element => {
    if (element.name != "首页" && element.name != "单位管理" && element.name != "更多功能及硬件") {
      element.children.forEach(item => {
        let indexOfUseAuth = util.findIndex(item.name, useAuth)
        item.meta.moduleId = useAuth[indexOfUseAuth] ? useAuth[indexOfUseAuth].id : ''
      })
    }
  })
}
//end

//埋点请求方法
let sensorsAnalyticsFn = function (p) {
  let id = p.id, obj = { platform: p.platform };
  sensorsAnalytics(id, obj).then(res => {
    // console.log('埋点请求成功:' + res)
  }).catch(err => {
    // console.log('埋点请求失败:' + err)
  })
}



let router = new VueRouter({
  mode: 'history',
  routes
})



router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem('token')
  let isAdmin = localStorage.getItem('isAdmin')
  let useAuth = JSON.parse(localStorage.getItem("useAuth"));
  let expandAuth = JSON.parse(localStorage.getItem('expandAuth'));
  //免密登录,清空当前登录状态
  let url = location.search;
  if (url.indexOf("?") != -1 ) {
    let queryStr=location.search.split("?")[1];
 
    let arr=queryStr.split("&");
 
    let obj={};
 
    for(let i=0;i<arr.length;i++){
        let g=arr[i].split("=");
        obj[g[0]]=g[1];
    }
    

    // if(obj.logintype == 'easy'){
    //   localStorage.removeItem("token");
    //   token = '';
    // }
  }

  if (token) {
    // start 在首页和经营报表模块下收起左侧导航栏,其他要展开
    if(to.path === '/Dashboard' || to.path === '/UserAccountReport' || to.path === '/UserConsumeReport' || to.path === '/CanteenOperationReport'){
      store.commit('CHANGE_COLLAPSED_TRUE')
    }
    // else{
    //   store.commit('CHANGE_COLLAPSED_FALSE')
    // }
    //end
    let {
      data
    } = await store.dispatch("GET_ME", {
      version: localStorage.getItem("version")
    })
    useAuth = JSON.parse(localStorage.getItem("useAuth"));//这边需要再拿一次userAuth不然userAuth是空的（null）
    await addModuleId(useAuth)

    //start 模块埋点
    if (to.meta.moduleId) {
      let param = {
        id: to.meta.moduleId,
        platform: 1
      }
      await sensorsAnalyticsFn(param)
    }
    //end

    if (!data.version) {
      //message.warning('系统即将更新,将在3秒后退出登陆', 3, () => {
      //  store.commit('REMOVE_COUNT')
      //  return
      //})
    }
    if (to.path === "/Dashboard" || to.path==='/easyLogin' || to.path==='/Renew' || to.path==='/ServiceModules' || to.path==='/ServiceOrder' ) {
      next()
    } else if (to.path === "/login") {
      next({
        path: '/Dashboard'
      })
    } else {
      let find = util.findIndex(to.name, useAuth)
      let findExpand = util.findIndexByTitle(to.name, expandAuth)
      if (find != -1 || findExpand != -1) {
        // console.log('有权限')
        next()
      } else {
        if (to.meta.parent == "单位管理") {
          next()
        } else {
          next()
          return
          // 判断是否有权限进入，管理员需跳转页面
          if (isAdmin == "true") {
            Modal.error().update({
              content: '您没有权限进入,请先前往续费',
            })
          } else {
            Modal.error().update({
              content: '您没有权限进入,请联系管理员开通权限',
            })
          }

        }

      }

    }

  } else {

    if (to.path === '/login' || to.path === '/easyLogin') {
      next()
    } else {
      return next({
        path: '/login'
      })
    }
  }

  // console.log('记录模块停留时间的参数')
  // console.log(util.createModuleStayTime(to.meta.moduleId))
  let param = util.createModuleStayTime(to.meta.moduleId);
  if(JSON.stringify(param) != '{}'){
    let res = await moduleStayTime(param);
    let preId = res.data && res.data.data ? res.data.data.id : null;
    if(preId){
      localStorage.setItem('pre_module_id',JSON.stringify(preId))
    }
  }
  
})

export default router