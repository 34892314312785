<template>
  <div class="main">
    <div class="grayBc">
      <p class="blue">遇到问题？</p>
      <p class="light_blue">Encounter problems</p>
      <div v-if="!setPwd" class="forgetPwdBtn" @click="visible = true">
        忘记密码
      </div>
      <!-- 白底盒子 -->
      <div class="form_Box">
        <div class="sign_title" v-if="setPwd">
          {{ setPwd == false ? "登录 Sign in" : "设置新密码" }}
        </div>
        <div class="login-type-card" v-if="!setPwd">
			<a v-if="!checkLogin" :class="pswLogin?'login-type-sel':''" @click="changeLoginType">账号登录</a>
			<a v-if="!checkLogin" :class="!pswLogin?'login-type-sel':''" @click="changeLoginType">手机号登录</a>
			<a v-if="checkLogin" class="login-type-sel">请选择要登录的食堂</a>
        </div>
        <!-- 登录表单 -->
        <a-form-model
			v-if="!setPwd&&!checkLogin"
			:model="loginData"
			@submit="handleSubmit"
			@submit.native.prevent
        >
			<div v-if="pswLogin">
				<a-form-model-item>
					<a-input v-model="loginData.username" placeholder="请输入账号">
						<a-icon
							slot="prefix"
							type="user"
							style="color: rgba(0, 0, 0, 0.25)"
						/>
					</a-input>
				</a-form-model-item>
				<span class="err-tips" v-if="errAccount && !setPwd">{{errAccountText}}</span>
				<a-form-model-item style="margin-top:15px;">
					<a-input
						v-model="loginData.password"
						type="password"
						placeholder="请输入密码"
					>
					<a-icon
						slot="prefix"
						type="lock"
						style="color: rgba(0, 0, 0, 0.25)"
					/>
					</a-input>
				</a-form-model-item>
				<span class="err-tips2" v-if="errPsw && !setPwd">{{errPswText}}</span>
				<a-form-model-item style="margin-top:15px;">
					<a-input v-model="loginData.captcha" placeholder="请输入验证码">
						<a-icon
							slot="prefix"
							type="key"
							style="color: rgba(0, 0, 0, 0.25)"
						/>
					</a-input>
					<img
						@click="changeVerficationFn"
						class="verification_pic"
						:src="verificationSrc"
						alt
					/>
				</a-form-model-item>
				<div>
				<span class="err-tips3" v-if="errCode && !setPwd">{{errCodeText}}</span>
				<a-button
					style="color: #ff833d"
					type="link"
					class="changePic"
					@click="changeVerficationFn"
					>看不清？换一张
				</a-button>
				</div>
			</div>
			<div  v-if="!pswLogin">
				<a-form-model-item>
				  <div style="display:flex;justify-content:start;">
					<a-input v-model="loginData.username" placeholder="请输入手机号">
					  <a-icon
						slot="prefix"
						type="user"
						style="color: rgba(0, 0, 0, 0.25)"
					  />
					</a-input>
					<a-input
					  style="width: 120px"
					  v-model="btnText == null ? countNum + 's后重新发送' : btnText"
					  type="button"
					  class="sendCaptchaBtn"
					  @click="getLoginCode"
					  :disabled="countFlag"
					></a-input>
				  </div>
				</a-form-model-item>
				<span class="err-tips4" v-if="errAccount && !setPwd">{{errAccountText}}</span>
				<a-form-model-item style="margin-top:20px;">
				  <a-input
					v-model="loginData.password"
					type="text"
					placeholder="请输入验证码"
				  >
					<a-icon
					  slot="prefix"
					  type="lock"
					  style="color: rgba(0, 0, 0, 0.25)"
					/>
				  </a-input>
				</a-form-model-item>
				<span class="err-tips5" v-if="errCode && !setPwd">{{errCodeText}}</span>
			</div>
          
          
          <a-form-model-item style="margin-top:20px;">
            <a-button
              block
              type="primary"
              html-type="submit"
              :disabled="
                loginData.user === '' ||
                loginData.password === '' ||
                (loginData.captcha === '' && pswLogin)
              "
              >登 录</a-button
            >
          </a-form-model-item>
        </a-form-model>
		<!-- 选择登录账号 -->
		<a-form-model
			v-else-if="!setPwd&&checkLogin"
			:model="loginData"
			@submit="handleSubmit"
			@submit.native.prevent>
			<div style="overflow-y: scroll;height: 180px;scrollbar-width: none;">
				<a-radio-group @change="changeLogin">
					<div v-if="hasAdminArr" >本手机号下的
            <span style="font-weight: 900;">超级管理员</span>
          </div>
				    <a-radio v-if="item.is_admin == 1" style="display:block;height:60px;lineHeight:30px;" v-for="item in userlist" :value="item.inst_id" :key="item.id" name="inst">
				        <span class="">{{ item.title }}</span>
				        <span class="radio-item-info">{{ item.username }}</span>
				    </a-radio>
					<div v-if="hasUserArr" >本手机号下的
            <span style="font-weight: 900;">子管理员</span>
          </div>
					<a-radio v-if="item.is_admin == 0"  style="display:block;height:60px;lineHeight:30px;" v-for="item in userlist" :value="item.inst_id" :key="item.id" name="inst">
						<span class="">{{ item.title }}</span>
						<span class="radio-item-info">{{ item.username }}</span>
					</a-radio>
				</a-radio-group>
			</div>
			<div style="text-align: right;">
				<a class="ant-btn ant-btn-primary" @click="handleSubmit">确定</a>
				<a class="ant-btn ant-btn-info" @click="cancelCheck">取消</a>
			</div>
		</a-form-model>
		<!-- 选择登录账号结束 -->
        <!-- 修改密码表单 -->
        <a-form-model v-else :model="changePwdData">
          <a-form-model-item style="margin-top:18px;">

            <div style="display:flex;justify-content:start;">
              <a-input v-model="changePwdData.tel" placeholder="请输入手机号码">
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>

              <a-input
                style="width: 120px"
                v-model="btnText == null ? countNum + 's后重新发送' : btnText"
                type="button"
                class="sendCaptchaBtn"
                @click="sendCaptcha"
                :disabled="countFlag"
              ></a-input>
            </div>
            
          </a-form-model-item>
          <span class="err-tips" v-if="errAccount && setPwd">{{errAccountText}}</span>
          <a-form-model-item style="margin-top:18px;">
            <a-input
              v-model="changePwdData.code"
              placeholder="请输入短信验证码"
            >
              <a-icon
                slot="prefix"
                type="key"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-model-item>
          <span class="err-tips2" v-if="errCode && setPwd">{{errCodeText}}</span>

          <a-form-model-item style="margin-top:18px;">
            <a-input
              v-model="changePwdData.password"
              type="password"
              placeholder="请输入新密码"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-model-item>
          <span class="err-tips3" v-if="errPsw && setPwd">{{errPswText}}</span>

          <a-form-model-item>
            <a-button type="link" class="changePic" @click="goLogin()"
              >去登录</a-button
            >
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              block
              type="primary"
              html-type="submit"
              :disabled="
                changePwdData.tel === '' ||
                changePwdData.code === '' ||
                changePwdData.password === ''
              "
              @click="handleResetPwd"
              >确 定</a-button
            >
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <!-- 模态框 -->
    <a-modal
      v-model="visible"
      title="操作提示"
      okText="继续"
      cancelText="取消"
      @ok="handleOk"
      destroyOnClose
    >
      仅食堂超级管理员可通过手机验证码找回密码，其他管理员请联系超级管理员重置密码。</a-modal
    >
  </div>
</template>

<script>
import {
  login,
  easyLogin,
  getCaptchaImage,
  getWebCode,
  getLoginCode,
  resetInstPass,
  loginStatus,
  isRemind
} from "@/request/api";
import util from "@/utils/util";

export default {
  data() {
    return {
      loginData: {
        username: "",
        password: "",
        platform: "web",
        captcha: "",
        key: "",
      },
      verificationSrc: "",
      setPwd: false, //切换登录和修改密码界面的标识
      changePwdData: {
        tel: "",
        password: "",
        code: "",
      },
      visible: false,
      btnText: "发送验证码",
      countNum: 60,
      // 用于倒计时标记，true-正在倒计时
      countFlag: false,
      // 定时器
      intervalBtn: {},
      checkLogin:false,
      pswLogin:true,
      errAccount:false,
      errAccountText:'',
      errPsw:false,
      errPswText:'',
      errCode:false,
      errCodeText:'',
	    userlist:[],
	    inst_id:0,
      hasAdminArr:false,
      hasUserArr:false
    };
  },
  computed: {},
  components: {},
  created() {
    this.changeChrome();
    this.getCaptchaImageFn();
    this.checkEasyLogin();
  },
  mounted() {},
  methods: {
    //免密登录
    checkEasyLogin(){
      let loginType = this.$route.query.logintype;
      
      if(loginType == 'easy'){
        //用户使用免密登录
        let parame = {
          inst:this.$route.query.inst,
          nonce:this.$route.query.nonce,
          timestamp:this.$route.query.timestamp
        };
        easyLogin(parame)
          .then(async (res) => {
            // window.history.replaceState(null,null,'');
            if(res.data.error_code == 1){
              this.$message.error(res.data.message);
              return;
            }
            localStorage.setItem('Login-Type','easyLogin');
            this.$message.success("登录成功!");
            this.$store.commit("ADD_COUNT", res.data.access_token);
            this.$store.commit("ADD_VERSION", res.data.version);
            await this.$store.dispatch("GET_INST_INFO");
            this.$store.dispatch("GET_ME").then((res) => {
              this.$store.dispatch("GET_ALL_AUTH").then(async (res) => {
                this.$router.push("/");
              });
            });
          })
          .catch((err) => {
            console.log(err)
          });
      }
    },
    //继续
    handleOk() {
      //清空错误提示
      this.errAccount = false;
      this.errPsw     = false;
      this.errCode    = false;
      this.setPwd = true;
      this.visible = false;
      this.loginData.username = "";
      this.loginData.password = "";
      this.loginData.captcha = "";
    },
    goLogin(){
      //清空错误提示
      this.errAccount = false;
      this.errPsw     = false;
      this.errCode    = false;
      this.setPwd = false;
      this.changePwdData.tel = "";
      this.changePwdData.password = "";
      this.changePwdData.code = "";
    },
    //发送短信验证码
    sendCaptcha() {
      //清空错误提示
      this.errAccount = false;
      this.errPsw     = false;
      this.errCode    = false;
      getWebCode({ tel: this.changePwdData.tel }).then((res) => {}).catch((err) => {
          if (err.status == 201) {
            this.$message.success(err.data.msg);
            this.countDown();
          } else {
            //this.$message.error(err.data.msg);
            if(err.data.error_code == 1){
              //账号类
              this.errAccountText = err.data.msg;
              this.errAccount = true;
            } else if(err.data.error_code == 2){
              //密码类
              this.errPswText = err.data.msg;
              this.errPsw = true;
            } else {
              //验证码
              this.errCodeText = err.data.msg;
              this.errCode = true;
            }
          }
      });
    },
    //倒计时
    countDown() {
      this.btnText = null;
      this.countFlag = !this.countFlag;
      // 设置倒计时
      this.intervalBtn = setInterval(() => {
        if (this.countNum <= 0) {
          // 重置btn提示信息
          this.btnText = "发送验证码";
          // 清除定时器
          clearInterval(this.intervalBtn);
          // 更改btn状态
          this.countFlag = !this.countFlag;
          // 重置倒计时状态
          this.countNum = 60;
        }
        // 倒计时
        this.countNum--;
      }, 1000);
    },

    //获取验证码
    getCaptchaImageFn() {
      getCaptchaImage().then((res) => {
        this.verificationSrc = res.data.img;
        this.loginData.key = res.data.key;
      });
    },
    loginFunction() {
		this.loginData.type = this.pswLogin?'':'smscode';
		if(this.checkLogin){
			this.loginData.inst_id = this.inst_id;
		}
		login(this.loginData)
      .then(async (res) => {
        console.log(res);
        this.$message.success("登录成功!");
        this.$store.commit("ADD_COUNT", res.data.access_token);
        this.$store.commit("ADD_VERSION", res.data.version);
        await this.$store.dispatch("GET_INST_INFO");
        this.$store.dispatch("GET_ME").then((res) => {
          this.$store.dispatch("GET_ALL_AUTH").then(async (res) => {
            this.$router.push("/");
            // 请求接口判断是否需要弹窗过期提醒
            let { data } = await isRemind();
            if(data.length != 0){
              this.$info({
                content: data.msg,
              });
            }
          });
        });
      })
      .catch((err) => {
			  // this.$message.error(err.data.error_code);
			  console.log(err)
			  if(typeof(err.data.error_code) != 'undefined'){
          if(err.data.error_code == -1){
            //存在多个账号
            this.checkLogin = true;
            this.userlist = err.data.data;
            for (let index = 0; index < this.userlist.length; index++) {
              if( this.userlist[index].is_admin == 1 ){
                this.hasAdminArr = true;
              } else {
                this.hasUserArr = true;
              }
            }
          } else if(err.data.error_code == 1){
            //账号类错误
            this.errAccountText = err.data.msg;
            this.errAccount = true;
          }else if(err.data.error_code == 3){
            //验证码
            this.errCodeText = err.data.msg;
            this.errCode = true;
          } else {
            //密码类错误
            this.errPswText = err.data.msg;
            this.errPsw = true;
          } 
			  }
        // this.errCodeText = err.data.msg;
        // this.errCode = true;
			  this.getCaptchaImageFn();
      });
    },
    //登录
    handleSubmit() {
		//清空错误提示
		this.errAccount = false;
		this.errPsw     = false;
		this.errCode    = false;

		let param = {
			username: this.loginData.username,
			password: this.loginData.password,
			type:this.pswLogin?'':'smscode'
		};
		this.loginFunction();
		return;
		//旧版登录
		loginStatus(param)
			.then((res) => {
				let status = res.data.status;
				if (status == 1) {
					// console.log(0, '游客')
					this.$confirm({
						content:"您在其他食堂有申请记录，请联系该食堂申请通过记录，如需加入该新食堂可通过>添加用餐人员，扫描加入二维码，扫描成功后将会与之前的食堂脱离",
						onOk: () => {
							this.loginFunction();
						},
					});
				}
				if (status == 2) {
					// console.log(1, '有申请记录')
					this.$confirm({
						content:"您当前登陆的食堂，与您目前在《食堂有饭报餐管理》小程序的食堂不一致，如需加入该新建食堂可通过>添加用餐人员，扫描加入二维码，扫描成功后将会与之前的食堂脱离",
						onOk: () => {
							this.loginFunction();
						},
					});
				} else {
					this.loginFunction();
				}
			})
			.catch((err) => {
				//this.$message.error(err.data.msg);
				if(err.data.error_code == 1){
					//账号类
					this.errAccountText = err.data.msg;
					this.errAccount = true;
				} else if(err.data.error_code == 3) {
					//验证码
					this.errCodeText = err.data.msg;
					this.errCode = true;
				} else {
					//密码类
					this.errPswText = err.data.msg;
					this.errPsw = true;
				}
				return;
			});
		},
		changeVerficationFn() {
			this.getCaptchaImageFn();
		},
    // 引导使用谷歌浏览器
    changeChrome() {
      // console.log(util.myBrowser());
      if (util.myBrowser() != "Chrome") {
        this.$message.info("为减少不必要的BUG，请使用谷歌浏览器登录系统");
      }
    },
    //更改密码
    handleResetPwd() {
      this.errAccount = false;
      this.errPsw     = false;
      this.errCode    = false;
      resetInstPass(this.changePwdData)
        .then((res) => {})
        .catch((err) => {
          if (err.status == 201) {
            this.$message.success(err.data.msg);
            this.setPwd = true;
            this.$nextTick();
          } else {
            //this.$message.error(err.data.msg);
            if(err.data.error_code == 1){
              //账号类
              this.errAccountText = err.data.msg;
              this.errAccount = true;
            } else if(err.data.error_code == 2){
              //密码类
              this.errPswText = err.data.msg;
              this.errPsw = true;
            } else {
              //验证码
              this.errCodeText = err.data.msg;
              this.errCode = true;
            }
            return;
          }
        });
    },
    changeLoginType(){
      //清空错误提示
      this.errAccount = false;
      this.errCode = false;
      this.errPsw  = false;
      this.errAccountText='';
      this.errPswText  = '';
      this.errCodeText = '';

      //清空输入框内容
      this.loginData.username = "";
      this.loginData.password = "";
      this.loginData.captcha = "";

      this.pswLogin = !this.pswLogin;
    },
    getLoginCode(){
      //清除错误提示
      this.errAccount = false;
      this.errPsw     = false;
      this.errCode = false;

      if (!this.loginData.username) {
        this.errAccountText = "手机号码不能为空";
        this.errAccount = true;
        return;
      }

      getLoginCode({ tel: this.loginData.username }).then((res) => {}).catch((err) => {
          if (err.status == 201) {
            this.$message.success(err.data.msg);
            this.countDown();
          } else {
            //this.$message.error(err.data.msg);
            if(err.data.error_code == 1){
              //账号类
              this.errAccountText = err.data.msg;
              this.errAccount = true;
            } else if(err.data.error_code == 2){
              //密码类
              this.errPswText = err.data.msg;
              this.errPsw = true;
            } else {
              //验证码
              this.errCodeText = err.data.msg;
              this.errCode = true;
            }
            return;
          }
      });
    },
	changeLogin(v){
		console.log(v.target.value);
		this.inst_id = v.target.value;
	},
	cancelCheck(){
		this.checkLogin = false;
	}
  },
};
</script>
<style lang='less' scoped>
@import '~@/style/style.less';
.main {
  background-image: url("https://styf.oss-cn-shenzhen.aliyuncs.com/business/productions/202107/162734997247541.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  .grayBc {
    position: relative;
    padding: 15px 20px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 10%,
      rgba(0, 0, 0, 0.3) 100%
    );
    width: 800px;
    height: 240px;
    .blue {
      color: @color-main;
      margin-bottom: 0;
      font-size: 20px;
    }
    .light_blue {
      color: #cee1e9;
      margin-top: 0;
      font-size: 11px;
    }
    .forgetPwdBtn {
      border: 1px solid #cee1e9;
      color: #cee1e9;
      width: 160px;
      height: 35px;
      text-align: center;
      line-height: 30px;
    }
    .forgetPwdBtn:hover {
      cursor: pointer;
      color: white;
      border: 1px solid white;
    }
    .form_Box {
      position: absolute;
      right: 30px;
      top: -50px;
      width: 380px;
      height: 320px;
      background-color: #fafafa;
      padding: 20px 20px 10px 20px;
      .sign_title {
        color: @color-main;
        font-size: 23px;
        font-weight: 100;
      }
      .ant-form-item {
        margin: 0;
      }
      .sendCaptchaBtn {
        
        color:#fff;
        background-color:#FF833D;
      }
      .verification_pic {
        // z-index: 999!important;
        width: 100px;
        height: 30px;
        position: absolute;
        top: -6px;
        right: 1px;
      }
      .changePic {
        float: right;
        color: #12a6fe;
        margin: 0;
      }
      .login-type-card{
        margin:0 0 20px;
      }
      .login-type-card a{
        color:#000;
        font-weight:500;
        font-size:18px;
        margin-right:20px;
      }
      .login-type-sel{
        color:#FF833D!important;
        border-bottom:2px solid #FF833D;
      }
      .err-tips{
        color:red;
        padding-left:15px;
        font-size:8px;
        position: absolute;
        top: 104px;
        z-index:999;
      }
      .err-tips2{
        color:red;
        padding-left:15px;
        font-size:8px;
        position: absolute;
        top: 160px;
        z-index:999;
      }
      .err-tips3{
        color:red;
        padding-left:15px;
        font-size:8px;
        position: absolute;
        top: 215px;
        z-index:999;
      }
      .err-tips4{
        color:red;
        padding-left:15px;
        font-size:8px;
        position: absolute;
        top: 100px;
        z-index:999;
      }
      .err-tips5{
        color:red;
        padding-left:15px;
        font-size:8px;
        position: absolute;
        top: 156px;
        z-index:999;
      }
    }
	.radio-item-info{
		display: block;
		padding-left: 25px;
	}
	.ant-btn-info{
		color: #FF833D;
		background-color: #fff;
		border-color: #FF833D;
		text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
		box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
		margin-left: 10px;
	}
  }
}
</style>
